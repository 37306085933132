<template>
  <div class="page-folder-add">
    <div class="my-page">
      <van-form @submit="save" style="background-color: transparent">
        <div class="my-card">
          <van-field required name="accessoryList" label="上传图片">
            <template #input>
              <van-uploader
                v-model="accessoryList"
                :deletable="true"
                :preview-full-image="false"
                accept="image/*"
                :before-read="beforeRead"
                @click-preview="review"
              />
            </template>
          </van-field>
        </div>
        <van-button
          :loading="loading"
          block
          color="#ffe500"
          type="info"
          native-type="submit"
          >完成</van-button
        >
      </van-form>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Form,
  Field,
  Uploader,
  Popup,
  Checkbox,
  CheckboxGroup,
  Toast,
} from "vant";
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Toast.name]: Toast,
  },
  data() {
    return {
      id: null,
      accessoryList: [],
      loading: false,
    };
  },
  mounted() {
    this.id = this.$route.query.id;
  },
  methods: {
    beforeRead(file) {
      if (file.size / 1024 > 1025) {
        this.$compress.photoCompress(
          file,
          {
            quality: 0.2,
          },
          (base64Codes) => {
            file = this.$compress.dataURLtoFile(base64Codes, file.name);
            Toast.loading({
              message: "加载中...",
              duration: 0,
            });
            let formData = new FormData();
            formData.append("FileName", file.name);
            formData.append("LastFile", file);
            formData.append("ParentID", this.id);
            this.$axios
              .upload("/api/ClassFile/UploadFile", formData)
              .then((res) => {
                Toast.clear();
                if (res.code === 200) {
                  this.accessoryList.push(res.data);
                  return true;
                } else {
                  this.$toast.fail(res.msg || "操作失败");
                  return false;
                }
              });
          }
        );
      } else {
        Toast.loading({
          message: "加载中...",
          duration: 0,
        });
        let formData = new FormData();
        formData.append("FileName", file.name);
        formData.append("LastFile", file);
        formData.append("ParentID", this.id);
        this.$axios
          .upload("/api/ClassFile/UploadFile", formData)
          .then((res) => {
            Toast.clear();
            if (res.code === 200) {
              this.accessoryList.push(res.data);
              return true;
            } else {
              this.$toast.fail(res.msg || "操作失败");
              return false;
            }
          });
      }
    },
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    save() {
      let that = this;
      if (this.accessoryList && this.accessoryList.length > 0) {
        this.$axios
          .post("/api/ClassFile/AddFile", {
            FolderID: this.id,
            AccessoryPostList: this.accessoryList,
          })
          .then((res) => {
            if (res.code === 200) {
              this.$toast.success(res.msg || "操作成功");
              setTimeout(() => {
                this.$router.push({
                  path: "FolderView",
                  query: {
                    id: this.id,
                  },
                });
              }, 1000);
            } else {
              this.$toast.fail(res.msg || "操作失败");
            }
          });
      } else {
        this.$toast.fail("请至少上传一张图片");
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
